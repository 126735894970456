var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Card',{directives:[{name:"tw-merge",rawName:"v-tw-merge"}],staticClass:"relative flex min-h-0 flex-1 flex-col gap-4 overflow-hidden rounded-3xl p-4"},[_c('div',{staticClass:"flex items-center justify-between gap-4 pt-2"},[_c('h1',[_vm._v("Berichten")]),_c('div',{staticClass:"flex items-center justify-end gap-2"},[_c('messages-settings',{attrs:{"member-id":_vm.$props.memberId}}),_c(_vm.$route.name === 'Gebruiker nieuw bericht' ? 'button' : 'router-link',{directives:[{name:"show",rawName:"v-show",value:(_vm.showCreateButton),expression:"showCreateButton"}],tag:"component",class:_vm.buttonVariants({ color: 'legacy', class: 'gap-2' }),attrs:{"to":{ name: 'Gebruiker nieuw bericht' },"disabled":_vm.$route.name === 'Gebruiker nieuw bericht'}},[_vm._v(" Nieuw bericht "),_c('pfg-icon',{attrs:{"icon-id":"icon_plus"}})],1)],1)]),_c('div',{staticClass:"flex min-h-0 flex-1 justify-between gap-6"},[_c('div',{staticClass:"-m-4 flex min-h-0 flex-1 flex-col gap-4 overflow-y-auto p-4",style:({ flexBasis: '50%' })},[(_vm.status === 'loading')?_vm._l((Array.from({ length: 5 })),function(_,index){return _c('Skeleton',{key:index,staticClass:"rounded-lg",style:({ height: '3.625rem' })})}):(_vm.status === 'success' && _vm.data && _vm.data.data)?[(_vm.data.data.length <= 0)?_c('div',{staticClass:"grid h-24 place-content-center text-center"},[_vm._v(" Geen berichten ")]):_vm._l((_vm.data.data),function(message){return _c('router-link',{key:message.id,class:_vm.cx(
								_vm.buttonVariants({ class: 'h-auto' }),
								_vm.cardVariants({ class: 'rounded-lg shadow-none' }),
								'justify-between gap-3 p-4',
								Number(_vm.$route.params.messageId) === message.id
									? 'bg-blue bg-opacity-10 hover:bg-blue hover:bg-opacity-20 active:bg-blue active:bg-opacity-20'
									: 'bg-white hover:bg-gray-100 active:bg-gray-200'
							),attrs:{"to":{ name: 'Gebruiker bericht', params: { messageId: message.id } }}},[_c('strong',{staticClass:"truncate text-sm font-bold text-black",style:({ flexBasis: '50%' })},[_vm._v(" "+_vm._s(message.title)+" ")]),_c('span',{staticClass:"text-xs font-normal text-black-disabled",style:({ flexBasis: '8%' })},[_vm._v(" Automatisch ")]),_c('div',{staticClass:"flex items-center justify-end gap-1",style:({ flexBasis: '27%' })},[(message.scheduledOn)?_c('Badge',{staticClass:"gap-1 px-2 py-1 text-xs font-normal",style:({ color: '#7E300F', backgroundColor: '#FFEEDE' })},[_c('svg',{staticClass:"h-4 w-4",attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24","stroke-width":"1.5","stroke":"currentColor"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","d":"M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"}})]),_vm._v(" Wordt verzonden op ")]):_vm._e(),_c('time',{staticClass:"text-xs font-normal text-black-disabled",attrs:{"datetime":message.scheduledOn}},[_vm._v(" "+_vm._s(_vm.formatDate(new Date(message.scheduledOn), { dateStyle: "short", timeStyle: "short", }))+" ")])],1)])})]:_c('span',{staticClass:"text-error-active"},[_vm._v("Er ging iets mis.")])],2),_c('div',{class:_vm.cx(
					'absolute inset-0 flex min-h-0 transform xl:relative xl:transition-all',
					_vm.isSplit ? 'pointer-events-auto opacity-100' : 'pointer-events-none opacity-0'
				),style:(Object.assign({}, {flexBasis: '50%'},
				(_vm.isSplit ? { marginRight: '0' } : { marginRight: 'calc(calc(50% + 1.5rem) * -1)' })))},[_c('router-view')],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }