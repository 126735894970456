

























































































































import { defineComponent } from "@vue/composition-api";
import { useQuery } from "@tanstack/vue-query";
import MessagesSettings from "@/components/messages/Settings.vue";
import { coreApi } from "@/lib/backend";
import { buttonVariants } from "@/lib/pfg/components/button";
import { cardVariants } from "@/lib/pfg/components/card";
import Badge from "@/lib/pfg/vue2/badge/Badge.vue";
import Card from "@/lib/pfg/vue2/card/Card.vue";
import Skeleton from "@/lib/pfg/vue2/skeleton/Skeleton.vue";
import { cx } from "@/lib/style/cva.config";
import { formatDate } from "@/utils/date";

export default defineComponent({
	components: { MessagesSettings, Badge, Skeleton, Card },
	props: {
		memberId: {
			type: Number,
			required: true,
		},
	},
	setup(props) {
		/** FUTURE: Currently disabled as there is no ability to actually create a message */
		const showCreateButton = false;

		const { status, data } = useQuery({
			queryKey: ["member", props.memberId, "scheduled-notifications"] as const,
			queryFn: async (context) =>
				await coreApi.members
					.memberNotificationControllerGetScheduledNotifications(String(context.queryKey[1]), {
						signal: context.signal,
					})
					.then((response) => response.data),
		});

		return {
			showCreateButton,
			status,
			data,
			formatDate,
			cx,
			cardVariants,
			buttonVariants,
		};
	},
	computed: {
		isSplit(): boolean {
			return ["Gebruiker nieuw bericht", "Gebruiker bericht"].includes(this.$route.name ?? "");
		},
	},
});
